import React from 'react';
import styled from "styled-components";
import Header from "../components/common/Header";
import Welcome from "../components/main/Welcome";
import Platform from "../components/main/Platform";
import PricingInfo from "../components/main/PricingInfo";
import Tracking from "../components/main/Tracking";
import WhatYouNeed from "../components/main/WhatYouNeed";
import ControlAndManage from "../components/main/ControlAndManage";
import Footer from "../components/common/Footer";
import Specifications from "../components/main/Specifications";
import PeaceOfMind from "../components/main/PeaceOfMind";
import FAQ from "../components/main/FAQ";

const Wrapper = styled.div`
  .content {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 3.2rem;
      color: ${props => props.theme.text};
      font-weight: bold;
    }

    .accordion {
      width: 100%;
    }
  }
`;
const Home = () => {
    return (<Wrapper>
        <Header/>
        <Welcome/>
        <WhatYouNeed/>
        <Tracking/>
        <PeaceOfMind/>
        <ControlAndManage/>
        <PricingInfo/>
        <Platform/>
        <Specifications/>
        <FAQ/>
        <Footer/>
    </Wrapper>);
};

export default Home;
