import React from 'react';
import styled from "styled-components";
import netwroking from "../../assets/networking.png";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  padding: 20px 0;

  h1 {
    font-size: 3.2rem;
    color: ${props => props.theme.text};
    font-weight: bold;
    text-align: center;
  }

  p {
    margin: 10px 0;
    color: ${props => props.theme.text};
  }

  img {
    width: 100%;
    max-width: 650px;
  }

  .item {
    max-width: 500px;
  }

  p {
    padding-left: 0.3em;
  }
`;
const Platform = () => {
    const {t} = useTranslation();

    return (<Wrapper>
        <div className="container">
            <h1>{t('eLOK® PLATFORM')}</h1>

            <div className="flex justify-around align-center flex-wrap">
                <img src={netwroking}/>
                <div className="item">
                    <p>
                        {t('networking_one')}
                    </p>
                    <p>
                        {t('networking_two')}
                    </p>
                    <p>
                        {t('networking_three')}
                    </p>
                </div>
            </div>
        </div>
    </Wrapper>);
};

export default Platform;
