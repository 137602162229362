import React, {useState} from 'react';
import styled from "styled-components";
import {MenuIcon} from "../icons";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  position: relative;

  button {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.2em;

  }

  .items {
    right: 0;
    background-color: #fff;
    position: absolute;
    padding: 0;
    //padding: 10px 0;

    a {
      cursor: pointer;
      background: unset;
      text-decoration: none;
      margin: 0 !important;
      border: none !important;
      padding: 5px 10px;
      width: 100px;
      height: 30px;
      transition: 0.1s;

      &:hover {
        transition: 0.1s;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
`;
const Dropdown = ({items, customButton}) => {
    const {t} = useTranslation();
    const [active, setActive] = useState(false);
    return (<Wrapper>
        <button onClick={() => setActive(!active)}>
            {customButton ? customButton : <MenuIcon color={'#000'}/>}
        </button>
        {active && <div className="items flex flex-column">
            {items.map((item, key) => <a key={key} href={item.url} onClick={item?.onClick ? item.onClick : () => {
            }}>
                {t(item.title)}
            </a>)}
        </div>}
    </Wrapper>);
};

export default Dropdown;
