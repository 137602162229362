import React from 'react';

const ICShield = (props) => {
    return (<svg width="80" height="100" {...props} viewBox="0 0 80 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M79.5567 38.4515C79.6058 47.8481 80.0475 57.22 76.4893 66.2457C69.8392 83.1597 57.3488 93.5454 40.8585 99.7274C39.4106 100.271 38.2818 99.7522 36.9813 99.2823C14.0862 90.9243 0.123421 70.9193 0.0988823 46.3892C0.0988823 39.3911 0.270657 32.3684 0.0252647 25.3704C-0.0974312 21.859 0.933215 19.8066 4.14785 18.1498C14.8715 12.6355 25.4479 6.89856 35.9997 1.06275C38.3064 -0.198377 40.1714 -0.445657 42.5517 0.840199C53.7661 6.8491 65.0295 12.7838 76.3912 18.5207C78.9433 19.8066 79.7285 21.3644 79.6304 24.1092C79.4095 28.8817 79.5567 33.679 79.5567 38.4515ZM40.0732 57.0469C44.2939 57.0469 48.5392 57.1953 52.76 57.0222C57.7905 56.8244 60.1463 53.857 60.1953 48.0954C60.2444 41.8887 57.9868 38.7729 52.7845 38.6493C44.4903 38.4762 36.196 38.4762 27.9018 38.6493C22.8712 38.7482 20.5155 41.8145 20.4664 47.5761C20.4173 53.7828 22.6995 56.7996 27.9018 57.0469C31.9507 57.1706 35.9997 57.0469 40.0732 57.0469Z"
                fill="#B2CC4A"/>
            <path
                d="M49.9854 40.6527C53.789 40.6527 57.1018 43.9415 57.1263 47.7744C57.1508 51.6072 53.8626 54.9455 50.059 54.9702C46.28 54.9949 42.9426 51.6814 42.9181 47.8486C42.8936 44.0157 46.1818 40.6527 49.9854 40.6527Z"
                fill="#484B52"/>
        </svg>
    );
};

export default ICShield;
