import React from 'react';

const ICPhoneConnect = (props) => {
    return (<svg width="55" height="100" {...props} viewBox="0 0 55 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.5302 0H41.0868H37.8504H16.1818H13.1169H10.5021C4.71523 0 0 5.4765 0 12.2035V28.3312V28.3959V28.4174V30.4226V38.9176V87.818C0 94.5451 4.71523 100.022 10.5021 100.022H43.5302C49.317 100.022 54.0323 94.5451 54.0323 87.818V50.2372V42.1087V12.2035C54.0323 5.4765 49.317 0 43.5302 0ZM47.9453 50.2372V87.7965C47.9453 90.621 45.9735 92.928 43.5302 92.928H10.5021C8.08019 92.928 6.08693 90.621 6.08693 87.7965V38.8961V30.401V24.9461V24.8814V24.8599V12.2035C6.08693 9.37904 8.05876 7.07201 10.5021 7.07201H14.2957C15.4102 8.75377 17.1463 9.76714 18.9681 9.76714H35.0642C36.9074 9.76714 38.622 8.77533 39.7365 7.07201H43.5302C45.9521 7.07201 47.9453 9.37904 47.9453 12.2035V42.1302V50.2372Z"
                fill="#B2CC4A"/>
            <path
                d="M36.2653 62.1171C24.5415 62.0956 15.0039 52.5009 14.9824 40.7071C14.9824 39.1547 16.247 37.8826 17.7901 37.8826H21.8409C23.3841 37.8826 24.6486 39.1547 24.6486 40.7071C24.6486 41.936 24.8415 43.165 25.2273 44.3293C25.5488 45.3211 25.2916 46.3992 24.6058 47.1538L23.2341 49.0081C24.37 51.0348 26.0632 52.7165 28.0565 53.8593L29.964 52.4147C30.7141 51.7463 31.7429 51.5307 32.7074 51.8541C33.8648 52.2422 35.065 52.4363 36.2867 52.4363H36.3081C37.8513 52.4363 39.1158 53.7084 39.1158 55.2607V59.3142C39.0515 60.8451 37.8084 62.1171 36.2653 62.1171ZM17.7687 40.0387C17.4043 40.0387 17.1043 40.3405 17.1043 40.7071C17.1257 51.3151 25.6989 59.961 36.2438 59.961C36.6082 59.961 36.9082 59.6592 36.9082 59.2927V55.2392C36.9082 54.8727 36.6082 54.5708 36.2438 54.5708H36.2224C34.7864 54.5708 33.3504 54.3336 31.9787 53.8808H31.9573C31.7429 53.7946 31.5072 53.8593 31.3357 54.0102L31.25 54.0749L28.2279 56.3604L27.6278 56.0585C24.7558 54.6139 22.3982 52.2206 20.9622 49.353L20.6621 48.7493L22.934 45.7092L22.9769 45.6661C23.1483 45.4936 23.2126 45.2133 23.1483 44.9761C22.6983 43.5962 22.4625 42.1517 22.4625 40.6855C22.4625 40.319 22.1624 40.0171 21.7981 40.0171H17.7687V40.0387Z"
                fill="#484B52"/>
        </svg>
    );
};

export default ICPhoneConnect;
