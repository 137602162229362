import React from 'react';
import styled from "styled-components";
import logo from "../../assets/logo.svg";
import device from "../../assets/device.png";
import {BrainIcon, CloudIcon, LocationIcon, OldPhoneIcon, PhoneConnectIcon, ShieldIcon} from "../icons";
import {useTranslation} from "react-i18next";


const WhatYouNeed = () => {
    const {t} = useTranslation();

    return (
        <Wrapper id={'useCases'}>
            <div className="container flex flex-column align-center justify-center">
                <div className="title flex align-center justify-center flex-wrap">
                    <h1>{t('Why')}</h1>
                    <img src={logo} alt=""/>
                    <h1>{t("Is What You Need")}</h1>
                </div>
                <div className="heading flex flex-column align-center justify-center">
                    <p>
                        {t('The eLOK® innovative tracker system allows you to protect your vehicle & track the location in real time - view all your routes.')}
                    </p>

                    <img src={device} alt="" className="device"/>
                </div>

                <div className="items flex justify-around align-center flex-wrap">
                    <div className="item flex align-start justify-start">
                        <ShieldIcon/>
                        <div className="text flex flex-column">
                            <h3>{t('Auto Anti-theft Mode')}</h3>
                            <p>
                                {t("elok® recognizes you and your family members (give that they authorize via Bluetooth) and automatically switches the anti-theft mode on or off.")}
                            </p>
                        </div>
                    </div>
                    <div className="item flex align-start justify-start">
                        <LocationIcon/>
                        <div className="text flex flex-column">
                            <h3>{t('GPS + GSM Tracking')}</h3>
                            <p>
                                {t('24/7 worldwide DOUBLE tracking. Even if GPS signal doesnt come through you’ll get the information of the stolen valuable’s location via GSM tracking.')}
                            </p>
                        </div>
                    </div>
                    <div className="item flex align-start justify-start">
                        <CloudIcon/>
                        <div className="text flex flex-column">
                            <h3>{t('eLOK® Cloud Center')}</h3>
                            <p>
                                {t('All setups in one place - the single Operation Center is able to manage all your data and share a lot of useful info.')}
                            </p>
                        </div>
                    </div>
                    <div className="item flex align-start justify-start">
                        <BrainIcon/>
                        <div className="text flex flex-column">
                            <h3>{t('Anti-theft AI')}</h3>
                            <p>
                                {t('elok® features a smart Anti-theft and Localization logic. It takes 5 secs only to identify danger and contact you.')}
                            </p>
                        </div>
                    </div>
                    <div className="item flex align-start justify-start">
                        <PhoneConnectIcon/>
                        <div className="text flex flex-column">
                            <h3>{t('Phone Notification')}</h3>
                            <p>
                                {t('Immediate PHONE CALL, SMS or EMAIL, APP while the actual theft is still happening.')}
                            </p>
                        </div>
                    </div>
                    <div className="item flex align-start justify-start">
                        <OldPhoneIcon/>
                        <div className="text flex flex-column">
                            <h3>{t('Old Phone Compatible')}</h3>
                            <p>
                                {t('NO Internet/GPRS or no Smartphone? No problem. elok® can send an SMS to a good old mobile phone.')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};
const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;

  .title {
    img {
      width: 7em;
      margin: 0 0.5em;
    }

    h1 {
      text-align: center;
      font-size: 3.2rem;
      color: ${props => props.theme.text};
      font-weight: bold;
    }
  }

  .heading {
    p {
      text-align: center;
      max-width: 500px;
      color: ${props => props.theme.text};
    }
  }

  .device {
    width: 100%;
    max-width: 700px;
    margin-top: 40px;
  }

  .items {
    margin: 30px 0;
    width: 100%;
    max-width: 1200px;

    .item {
      margin: 20px 0;
      flex: 0 0 auto;

      width: 100%;
      max-width: 450px;

      svg {
        flex: 0 0 auto;

        width: 10rem;
        height: 8rem;
        margin-right: 1em;
      }
    }

    h3, p {
      width: 100%;
      color: ${props => props.theme.text};
      text-align: start;
    }

    p {
      margin-top: 0.5em;
    }

    .text {
      //width: 300px;
    }
  }

  @media (max-width: 500px) {
    .items {
      .item {
        flex-wrap: wrap;
        justify-content: center;


        .text {
          margin-top: 10px;

          h3, p {
            text-align: center;
          }
        }
      }
    }
  }

`;

export default WhatYouNeed;
