import React from 'react';
import styled from "styled-components";
import theme from "../../config/theme";
import {useTranslation} from "react-i18next";

const Wrapper = styled.button`
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  width: 170px;
  padding: 10px 20px;
  background-color: ${props => props.color ? props.color : '#fff'};
  border: 2px solid ${props => props.border ? props.border : 'transparent'};

  span {
    font-weight: bold;
    font-size: 1.5em;
    color: ${props => props.textColor ? props.textColor : '#000'};
  }
`;
const Button = ({color = '#fff', textColor = theme.primary, text, border, onClick}) => {
    const {t} = useTranslation();

    return (
        <Wrapper onClick={onClick} border={border} color={color} textColor={textColor}>
            {text && <span>{t(text)}</span>}
        </Wrapper>
    );
};

export default Button;
