import React from 'react'

export default function ICMenu(props) {

    const {color} = props;
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"
                      stroke={color} d="M3 18H21"></path>
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"
                      stroke={color} d="M3 12H21"></path>
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"
                      stroke={color} d="M3 6H21"></path>
            </svg>
        </>
    )
}
