import React from 'react'

export default function ICTwitter(props) {

    const {color} = props;
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinejoin="round" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2"
                      stroke={color} fill="none"
                      d="M22 5.89C21.26 6.21 20.46 6.42 19.64 6.53C20.49 6.03 21.14 5.24 21.44 4.3C20.65 4.77 19.77 5.09 18.84 5.28C18.09 4.49 17.02 4 15.85 4C13.58 4 11.75 5.81 11.75 8.04C11.75 8.36 11.78 8.67 11.84 8.96C8.43999 8.79 5.41999 7.19 3.38999 4.74C3.03999 5.34 2.82999 6.03 2.82999 6.78C2.82999 8.18 3.55999 9.42 4.64999 10.13C3.98999 10.12 3.33999 9.93 2.79999 9.63V9.67C2.79999 11.63 4.21999 13.26 6.07999 13.64C5.74999 13.73 5.37999 13.77 4.99999 13.77C4.73999 13.77 4.46999 13.76 4.21999 13.7C4.74999 15.3 6.25999 16.48 8.04999 16.51C6.65999 17.59 4.87999 18.23 2.96999 18.23C2.62999 18.23 2.30999 18.21 1.98999 18.17C3.81999 19.34 5.96999 20 8.28999 20C15.84 20 19.96 13.85 19.96 8.51C19.96 8.33 19.95 8.16 19.94 7.99C20.76 7.42 21.44 6.71 22 5.89Z"></path>
            </svg>
        </>
    )
}
