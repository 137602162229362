import React from 'react';
import styled from "styled-components";
import luggage from "../../assets/luggage.png";
import delivery from "../../assets/delivery.png";
import locationDriving from "../../assets/locationDriving.png";
import {useTranslation} from "react-i18next";

const Card = ({image, title, text}) => {
    const {t} = useTranslation();
    return (<div className={'card flex flex-column justify-between align-start'}>
        <h3>{t(title)}</h3>
        <p>{t(text)}</p>
        <img src={image} alt=""/>
    </div>)
}
const ControlAndManage = () => {
    const {t} = useTranslation();

    return (
        <Wrapper>
            <div className="container">
                <h1>{t('Control. Protect. manage.')}</h1>
                <div className="flex justify-around flex-wrap">
                    <Card image={locationDriving} title={'Fleet vehicles'}
                          text={'Track your vehicles and improve your logistics efficiency'}/>
                    <Card image={luggage} title={'Valuable parcels'}
                          text={'Manage and protect your equipment against loss and theft'}/>
                    <Card image={delivery} title={'Packages Shipping'}
                          text={'Monitor transportation and delivery of valuable assets'}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  padding: 40px 0;
  background-color: #EFEFEF;

  h1 {
    font-size: 3.2rem;
    color: ${props => props.theme.text};
    font-weight: bold;
    text-align: center;
  }

  .flex {
    margin: 50px 0;
  }

  .card {
    max-width: 350px;
    height: 320px;

    h3 {
      font-size: 1.5em;
      color: ${props => props.theme.secondary};
    }

    p {
      margin-top: 0.5em;
      color: ${props => props.theme.text};
    }

    img {
      width: 350px;
      margin-left: -10px;
      margin-top: 1em;
    }
  }

  @media (max-width: 400px) {
    padding: 0 10px;
    .card {
      img {
        width: 300px;
      }
    }
  }
`;

export default ControlAndManage;
