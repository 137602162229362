import React from 'react';
import styled from "styled-components";
import tracking from "../../assets/track.png";
import {useTranslation} from "react-i18next";

const Tracking = () => {
    const {t} = useTranslation();
    return (
        <Wrapper style={{backgroundImage: `url(${tracking})`}}>
            <div className="container">
                <div className="flex flex-column">
                    <h1>{t('A web and')}</h1>
                    <h1>{t('mobile application')}</h1>
                    <h1>{t('to track your assets')}</h1>
                    <p>
                        {t('A simple web platform for your employees and you. A mobile application available on iOS and Android to enjoy the main features anytime, everywhere')}
                    </p>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  min-height: 600px;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 15px 0;


  .flex {
    max-width: 500px;
  }

  h1 {
    padding: 0 0.2em;
    color: #fff;
    font-size: 3.2rem;
    text-transform: capitalize;
  }

  p {
    padding: 0 0.3em;
    color: #fff;
  }
`;

export default Tracking;
