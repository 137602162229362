import React from 'react';
import styled from "styled-components";

import Button from "../common/Button";
import theme from "../../config/theme";
import WelcomeGroup from "../group/WelcomeGroup";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding-top: 100px;
  height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.secondary};

  .container {
    height: 100%;
    max-height: 550px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  img {
    max-width: 500px;
  }

  .titles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 500px;

    h1 {
      color: #fff;
      font-size: 4em;
      line-height: 1;
    }

    p {
      max-width: 450px;
      color: #fff;
    }
  }


  .actions {
    width: 100%;
    display: flex;
    max-width: 400px;
    justify-content: space-between;
  }

  @media (max-width: 1100px) {
    //height: 100%;
    .container {
      //max-height: unset;
    }

    .welcoming {
      display: none;
      //margin-top: 20px;
    }
  }


  @-webkit-keyframes gradientAnimation {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }

    100% {
      background-position: 0% 50%
    }
  }
  @-moz-keyframes gradientAnimation {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }

  @media (max-width: 500px) {
    height: 70vh;
    padding-bottom: 20px;
    .titles {
      padding: 0 10px;
      align-items: center;

      h1 {
        font-size: 2.5em;
      }
    }

    button {
      width: 130px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;
const Welcome = () => {
    const {t} = useTranslation();
    return (<Wrapper>
        <div className="container">
            <div className={'titles'}>
                <div className="first">
                    <h1>{t('GPS tracking of')}</h1>
                    <h1>{t('Vehicle fleets,')}</h1>
                    <h1>{t('Parcels and equipment')}</h1>
                    <h1>{t('For professionals')}</h1>
                </div>
                <div>
                    <p>
                        {t('Trackers and a web platform to locate and protect your vehicles, goods and equipment easily')}
                    </p>
                </div>
                <div className="actions">
                    <Button onClick={() => {
                        window.location = 'https://elok.bike/product?buy=Devices';
                    }} color={theme.primary} textColor={'#fff'} text={'BUY'}/>
                    <Button text={'Contact Us'} textColor={theme.primary} onClick={() => {
                        window.location = 'https://elok.bike/contact';
                    }}/>
                </div>
            </div>
            <div className="welcoming">
                <WelcomeGroup/>
            </div>
        </div>
    </Wrapper>);
};

export default Welcome;
