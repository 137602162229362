import React from 'react';
import styled from "styled-components";
import group3 from "../../assets/group3.png";
import line1 from "../../assets/line1.svg";
import line2 from "../../assets/line2.svg";
import group2 from "../../assets/group2.png";
import group1 from "../../assets/group1.png";

const Wrapper = styled.div`
  position: relative;
  width: 600px;
  height: 500px;

  img {
    position: absolute;
    width: 350px;
  }

  .line1, .line2 {
    position: absolute;
    z-index: 1;
  }

  .g1, .g2, .g3 {
    z-index: 2;
  }

  .g2 {
    left: -70px;
    bottom: 0;
  }

  .g3 {
    top: 50px;
    right: 0;
  }

  .g1 {
    bottom: -100px;
    right: 0;
  }

  .line1 {
    bottom: 100px;
    left: 80px;
  }

  .line2 {
    bottom: 50px;
    right: 100px;
  }
`;
const WelcomeGroup = () => {
    return (
        <Wrapper>
            <img className={'g1'} src={group3} alt=""/>
            <img className={'line1'} src={line1} alt=""/>
            <img className={'line2'} src={line2} alt=""/>
            <img className={'g2'} src={group2} alt=""/>
            <img className={'g3'} src={group1} alt=""/>
        </Wrapper>
    );
};

export default WelcomeGroup;
