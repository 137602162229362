import React from 'react';
import styled from "styled-components";
import man from "../../assets/man.png";
import Button from "../common/Button";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  padding: 20px 0;
  padding-bottom: 0;
  background-color: ${props => props.theme.primary};

  img {
    width: 300px;
    margin-bottom: -5px;
  }

  .texts {
    padding: 0 0.3em;
    max-width: 400px;
    height: 250px;
  }

  h2 {
    font-size: 3em;
    font-weight: 200;
  }

  .bd {
    font-weight: bold;
  }

  h2, p {
    color: #fff;
  }
`;

const PricingInfo = () => {
    const {t} = useTranslation();

    return (<Wrapper id={'pricing'}>
        <div className="container">
            <div className="flex align-center justify-center flex-wrap">
                <div className="texts flex flex-column justify-around">
                    <div className="t">
                        <h2>{t('Clear And')}</h2>
                        <h2 className={'bd'}>{t('Affordable rates')}</h2>
                    </div>
                    <p>
                        {t('GPS Trackers starting at $159, volume-dependent price Subscription starting at $4.99 / month / tracker')}
                    </p>
                    <Button text={'See Pricing'} onClick={() => {
                        window.location = 'https://elok.bike/product?buy=Devices';
                    }}/>
                </div>
                <div className="item">
                    <img src={man} alt=""/>
                </div>
            </div>
        </div>
    </Wrapper>);
};

export default PricingInfo;
