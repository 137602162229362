import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import phoneBackground from "../../assets/phone-background.svg";
import phoneFrame from "../../assets/active-phone-frame.svg";
import relay from "../../assets/relay.svg";
import people from "../../assets/people.svg";
import bell from "../../assets/bell.svg";
import bus from "../../assets/bus.svg";
import wifi from "../../assets/wifi.svg";
import cog from "../../assets/cog.svg";
import wallet from "../../assets/wallet.svg";
import roundLocation from "../../assets/location-round.svg";

const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 1,
        // slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        // slidesToSlide: 1 // optional, default to 1.
    }
};


const Card = ({active, title, text, icon}) => {
    const {t} = useTranslation();

    return (
        <div className={'card flex-column flex align-center'}>
            <div className="card-image flex align-center justify-center"
                 style={{backgroundImage: `url(${active ? phoneFrame : phoneFrame})`}}>
                <img src={icon} alt=""/>
            </div>
            <h2>
                {t(title)}
            </h2>
            <p>
                {t(text)}
            </p>
        </div>)
}
const PeaceOfMind = () => {
    const {t} = useTranslation();

    const items = [
        {
            icon: people,
            title: 'Trusted Users Recognition',
            text: 'Thanks to Bluetooth module integrated on board you can pair eLOK with unlimited number of smartphones'
        },
        {
            icon: bus,
            title: 'Unauthorized Movement detection',
            text: 'In only 5 seconds of continuous movement eLOK will understand if it\'s the rightful owner touching the protected object'
        },
        {
            icon: bell,
            title: 'Anti-theft alerts',
            text: 'The owner and authorized persons will get an immediate Push notification, SMS message, Email or even a Phone Call, if enabled'
        },
        {
            icon: relay,
            title: 'GPS replay',
            text: 'View the itinerary eLOK has done at a certain interval of time'
        },
        {
            icon: wifi,
            title: 'GPS + GSM tracking',
            text: "The eLOK app will display the Thief's GPS locations with the exact address OR the approximate ones given the complex on-board and the eLOK CLOUD complex logic"
        },
        {
            icon: roundLocation,
            title: 'Geo-Fence',
            text: "Set a Circle or N-side polygon areas on the Map, and you'll get notified when the eLOK protected object gets enters or gets out of it"
        },
        {
            icon: wallet,
            title: 'Credit History',
            text: 'See all your SMS and Calls notifications'
        },
        {
            icon: cog,
            title: 'eLOK Setup',
            text: "Send real-time commands to your eLOK device via user-friendly chat interface"
        }
    ];
    return (<Wrapper activePhoneFrame={phoneFrame}>
        <div className="container">
            <h1>{t('Peace of mind from anywhere')}</h1>
            <h1>{t('With the companion app')}</h1>

            <div className="wrapper">
                <Carousel
                    centerMode={false}
                    autoPlay={false}
                    infinite={true} responsive={responsive}
                    arrows={true}
                >
                    {items.map((item, key) => <Card key={key} icon={item.icon} title={item.title} text={item.text}/>)}
                </Carousel>
            </div>
        </div>
    </Wrapper>);
};

const Wrapper = styled.div`
  padding: 50px 0;

  .wrapper {
    margin: 0 auto;
    margin-top: 3em;
    max-width: 1000px;
  }
  
  .react-multiple-carousel__arrow--left {
    left: 0;
  }

  .react-multiple-carousel__arrow--right {
    right: 0;
  }

  h1 {
    text-align: center;
    font-size: 1.8em;
    font-weight: bold;
    color: ${props => props.theme.text};
  }

  //.react-multi-carousel-item {
  //  max-width: 400px;
  //  min-width: 400px;
  //}

  .react-multi-carousel-item {
    //width: initial !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .card {
    height: 550px;
    //margin: 0 auto;
    max-width: 350px;

    .card-image {
      height: 350px;
      width: 300px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-position-x: 45px;
    }

    h2 {
      margin-top: 1em;
      color: ${props => props.theme.primary};
      font-size: 1.5em;
      text-align: center;
    }

    p {
      max-width: 250px;
      font-size: 1em;
      margin-top: 0.5em;
      text-align: center;
      color: ${props => props.theme.text};
    }
  }

  // .react-multi-carousel-item--active {
  //   &:nth-of-type(2+) {
  //     .card-image {
  //       background-size: contain;
  //       background-position: center;
  //       background-repeat: no-repeat;
  //       background-position-x: 45px;
    //       background-image: url(${props => props.activePhoneFrame}) !important;
  //     }
  //   }
  // }

  @media (max-width: 600px) {
    .react-multi-carousel-item {
      //width: 320px !important;
    }

    .card {
      height: 600px;
    }

    h2, p {
      width: 100%;
      //width: 200px;
    }
  }
`;

export default PeaceOfMind;
