import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {AccordionItem} from "../common/Accordion";

const FAQ = () => {
    const {t} = useTranslation();
    return (
        <Wrapper className="container content" id={'faq'}>
            <h1>FAQs</h1>
            <div className="accordion">
                <AccordionItem title={t('What Makes eLOK BIKE Unique?')} content={<div>
                    <ul>
                        <li>
                            {t('eLOK can call you to warn about the theft while it\'s still happening. Smart geo-location determines the exact address and proximity, using both the GPS coordinates and the cell radio relay coordinates. For example, it can tell you if your bike is 150 m away from this street or 50 m from that intersection.')}
                        </li>
                        <li>
                            {t('eLOK uses savvy BlueTooth recognition to identify the owner, as well as friends and family (up to 10 in all) for a complete, user-friendly experience. If you walk 15-20 m away from eLok, the connection will be lost, and the Anti-theft module will activate automatically.')}
                        </li>
                        <li>
                            {t('eLOK features a complex Microcontroller with intricate Anti-theft and Localization logic.')}
                        </li>
                        <li>
                            {t('eLOK is maintained by a Cloud Center that provides the fullest range of services to our customers, catering to their every need.')}
                        </li>
                        <li>
                            {t('eLOK can send notification via Phone calls, SMS, email, or all together to multiple phones in the language of the country it is currently located. In cases when GPRS connection cannot be established, notifications will be sent via SMS.')}
                        </li>
                        <li>
                            {t('The Cloud Center is able to remotely enable and disable the SIM cards inside eLOK anti-theft devices per customers’ request.')}
                        </li>
                        <li>
                            {t('The Cloud Center services are available in the languages of every country where eLOK operates.')}
                        </li>
                    </ul>
                </div>}/>
                <AccordionItem
                    title={t('How Is It Possible That eLOK Is Compatible With Bikes, Cars, Domestic Animals, Etc.?')}
                    content={<div>
                        <p>
                            <b>{t("eLOK features separate software for unique purposes:")}</b>
                        </p>
                        <ul>
                            <li>
                                {t('Dedicated software to use with bicycles (battery saving mode);')}
                            </li>
                            <li>
                                {t('Sophisticated software for cars and motorcycles;')}
                            </li>
                            <li>
                                {t("Special software for business arrangements;")}
                            </li>
                            <li>
                                {t('Novel software for professional cyclists.')}
                            </li>
                        </ul>
                        <p>
                            {t('Depending on your use, the Operations’ Center can switch them over for you or help you choose the one that is most suitable for your purpose.')}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("What Are eLOK's Size And Weight?")}
                    content={<div>
                        <p>
                            <b>{t("eLOK consists of two plates - one over another. The whole device is 130 mm long and 14 mm in height. It weighs about 30 gr ~(1 ounce,) with battery excluded.")}</b>
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("Is The Battery Always ON? What Is The Approximate Charging Period?")}
                    content={<div>
                        <p>
                            {t("Our developing team had managed to apply multiple battery saving modes. Only the Accelerator remains ON permanently. The other modules within eLok (BT, GSM, GPS) will only activate when the AntiTheft protocol comes in action, and an unauthorized movement is detected.")}
                        </p>
                        <p>
                            {t("Charging time is approximately 1,5 hours depending on the power source.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("How Different Is The eLOK Version With The BlueTooth Module? Is It More Battery-Consuming?")}
                    content={<div>
                        <p>
                            {t("eLOK is a high-tech gadget. It features a BlueTooth module 5.1 - one of the latest and most battery-saving on the market. It consumes only 17microAh. - WHEN and IF your value object is moving and consumes ZERO if the object is in stand-by.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("Is The GSM / GPRS Capable Of Connecting To 3G?")}
                    content={<div>
                        <p>
                            {t("Since the 2G network is barely even used anymore , eLOK works wonderfully with 3G. Moreover, there is the newest 5G/LTE version of eLOK , built under the highest international standards.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("How Can I Charge eLOK ?")}
                    content={<div>
                        <p>
                            {t("You can easily use any Micro USB 5V battery chargers (you should have plenty at home.) There’s no need to take the device out - the adapter is easy to reach from the outside. You can also charge it with a power bank, even while being on the road.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("Is The SIM Card Replaceable? Can I Travel Abroad And Use A Local Pre-Paid SIM?")}
                    content={<div>
                        <p>
                            {t("eLOK comes with a Nano SIM card inside. It is provided by one of the largest ISP in the world. The data strings are so small , eLOK works perfectly well with a subscription that costs around 3 euros per month (based on the type of subscription you use.) So no, you don’t have to change the card while traveling.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("How Much Signal Is Lost Because The Device Is Hidden Inside The Handlebars?")}
                    content={<div>
                        <p>
                            {t("On average, the device covers 10-14 bars while being hidden within the aluminum handlebars. So any signal is barely lost.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("What Language Will The Email And The SMS Notifications Use?")}
                    content={<div>
                        <p>
                            {t("Thanks to the multi-languaged Cloud Center, we are not limited to specific languages (like only English or only French.)")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("What About The Alternative Charging Sources?")}
                    content={<div>
                        <p>
                            {t("E-bikes and/or bikes with large batteries can be charging stations for eLOK as they are.")}
                        </p>
                        <p>
                            {t("Because of how compact eLOK is, you can connect it to any power source. We are currently working on providing various accessories for many charging possibilities.")}
                        </p>
                    </div>}/>
                <AccordionItem
                    title={t("How To Use eLOK ?")}
                    content={<div>
                        <p>
                            {t("So, you have to leave your bike unattended. No sweat, no worries. Park your bicycle, activate eLOK, and go around your business without giving a second thought to the safety of your two-wheeled pal - eLOK will take care of that. If the system detects non-authorized movement in dangerous proximity, it will notify you instantly. You will get a phone call and/or an SMS with a notification, along with the approximate address. A few seconds later, you will receive an email and/or another SMS with the exact address. You will be able to follow every action of your bike (and the alleged thief) on the map. Moreover, you can use multiple email addresses with eLOK, getting notifications on each of them. If you prefer not using a smartphone, eLOK would still be able to send you information using the GPS cell data.")}
                        </p>
                    </div>}/>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 3.2rem;
    color: ${props => props.theme.text};
    font-weight: bold;
  }

  .accordion {
    width: 100%;
  }
`;

export default FAQ;
