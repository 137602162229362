import React from 'react';
import styled from "styled-components";
import Button from "./Button";
import theme from "../../config/theme";
import {FacebookIcon, InstagramIcon, TwitterIcon} from "../icons";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  .first {
    padding: 40px 0;
    background-color: ${props => props.theme.secondary};

    a {
      background: transparent;
      color: #fff;
    }

    .logo {
      width: 200px;

      img {
        width: 100px;
        height: 100px;
      }
    }

    ul {
      width: 200px;
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 8px 0;
      }
    }
  }

  .second {
    padding: 20px 0;
    background-color: ${props => props.theme.primary};

    p {
      width: 100%;
      text-align: center;
      color: #fff;
    }
  }


  .field {
    width: 600px;
  }

  .socials {
    margin-top: 20px;

    a {
      margin: 0 7px;
    }

    svg {
      width: 40px;
      height: 40px;

      path {
        stroke: #fff;
      }

      &:hover {
        path {
          stroke: ${props => props.theme.primary};
        }
      }
    }
  }
}

p {
  color: #fff;
  margin: 5px 0;
}

.input {
  width: 100%;

  input {

    &::-webkit-input-placeholder {
      font-size: 1.2em;
    }

    margin-right: 10px;
    padding: 10px;
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 12px;
    outline: none;

    &:focus {
      outline: 2px solid ${props => props.theme.primary};
    }
  }
}

@media (max-width: 700px) {
  .field {
    width: 100%;
  }
`;
const Footer = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <div className="first">
                <div className="container">
                    <div className="flex justify-around flex-wrap align-center">
                        <a href="/" className="logo">
                            <img src="https://elok.bike/theme/svg/landing/elok-white.svg" alt=""/>
                        </a>
                        <ul>
                            <li>
                                <a href="https://elok.bike/contact">
                                    {t('Contacts')}
                                </a>
                            </li>
                            <li>
                                <a href="https://elok.bike/support">
                                    {t('Support')}
                                </a>
                            </li>
                            <li>
                                <a href="https://elok.bike/policy/privacy">
                                    {t('Privacy Policy')}
                                </a>
                            </li>
                            <li>
                                <a href="https://elok.bike/policy/sale">
                                    {t('Terms Of Sale')}
                                </a>
                            </li>
                            <li>
                                <a href="https://elok.bike/policy/service">
                                    {t('Terms Of Service')}
                                </a>
                            </li>
                        </ul>
                        <div className="item">
                            <div className="field">
                                <p>{t('Newsletter:')}</p>
                                <div className="input flex">
                                    <input
                                        placeholder={t('Sign up to receive latest eLok news and upcoming events!')}/>
                                    <Button color={theme.primary} textColor={'#fff'} text={'Submit'}/>
                                </div>
                            </div>
                            <div className="socials flex align-center">
                                <p>{t('Follow Us')}</p>
                                <div className="items">
                                    <a href="#">
                                        <InstagramIcon/>
                                    </a>
                                    <a href="#">
                                        <TwitterIcon/>
                                    </a>
                                    <a href="#">
                                        <FacebookIcon/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="second">
                <p>2022 eLOK.bike - SYSTEM PATENTED</p>
            </div>
        </Wrapper>
    );
};

export default Footer;
