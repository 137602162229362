import React, {memo, useState} from 'react';
import styled from "styled-components";
import {PlusIcon, RemoveIcon} from "../icons";
import theme from "../../config/theme";
import {useTranslation} from "react-i18next";

const AItem = styled.div`
  padding: 10px;
  border-bottom: 2px solid ${props => props.theme.text};


  .accordion-title {
    padding: 15px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.7em;
    font-weight: 400;
    color: ${props => props.theme.text};
  }

  .accordion-content {
    padding: 10px;
    color: ${props => props.theme.text};
    font-size: 1.3em;

    p {
      font-size: 1em;
      margin: 10px 0;
    }
  }

  button {
    border: none;
    cursor: pointer;
    outline: none;
    background-color: transparent;
  }

  ul {
    li {
      margin: 10px 0;
    }
  }

`;

export const AccordionItem = ({title, content}) => {
    const {t} = useTranslation();

    const [isActive, setIsActive] = useState(false);

    return (<AItem className="accordion-item" style={isActive ? {backgroundColor: '#EFEFEF'} : {}}>
        <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
            <h4>{t(title)}</h4>
            <button>{isActive ? <RemoveIcon color={theme.text}/> : <PlusIcon color={theme.text}/>}</button>
        </div>
        {isActive && <div className="accordion-content">{content}</div>}
    </AItem>);
};

export default memo(AccordionItem);
