import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import logo from "../../assets/logo.svg";
import useEventListener from "../../hooks/useEventListener";
import Dropdown from "./Dropdown";
import {ArrowBottomIcon} from "../icons";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  z-index: 9999;
  background-color: #fff;
  width: 100%;
  position: fixed;
  top: 0;
  box-shadow: 2px 0 61px -13px rgba(0, 0, 0, ${props => props.shadowOpacity});

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    img {
      width: 100px;
      height: 70px;
    }
  }

  .pages, .actions {
    display: flex;

    a {
      margin: 0 10px;
      color: ${props => props.theme.text};
    }

    .divider {
      width: 1px;
      background-color: ${props => props.theme.text};
    }

    .login {
      color: ${props => props.theme.secondary};
    }
  }

  .lang {
    text-transform: capitalize;
    font-weight: bold;
    color: ${props => props.theme.primary} !important;
  }

  .actions {
    a {
      font-weight: bold;
    }
  }

  .mobile {
    display: none;
  }

  @media (max-width: 600px) {
    .actions, .pages {
      display: none;
    }


    .mobile {
      display: flex;
    }
  }
`;

const Header = ({props}) => {

    const {t, i18n} = useTranslation();

    const [shadowLevel, setShadowLevel] = useState(window.scrollY / 100);

    const onScroll = useCallback(
        (e) => {
            setShadowLevel(window.scrollY / 100);
        }, []);

    useEventListener('scroll', onScroll);

    const links = [
        {
            url: '#useCases',
            title: 'Use Cases'
        },
        {
            url: '#pricing',
            title: 'Pricing'
        },
        {
            url: '#faq',
            title: 'FAQ'
        },
        {
            url: 'https://dashboard.elokcar.com/',
            title: 'Login'
        },
        {
            url: 'https://elok.bike/site/register',
            title: 'Sign Up'
        },
    ]

    useEffect(() => {
        const lang = localStorage.getItem('APP_LANGUAGE');
        if (lang) {
            i18n.changeLanguage(lang)
        }
    }, []);

    const changeLanguage = (lang) => {
        localStorage.setItem('APP_LANGUAGE', lang);
        i18n.changeLanguage(lang)
    }

    const languageLinks = [
        {
            onClick: () => changeLanguage('en'),
            title: 'English'
        },
        {
            onClick: () => changeLanguage('fr'),
            title: 'French'
        }
    ]
    return (<Wrapper shadowOpacity={shadowLevel > 1 ? 1 : shadowLevel}>
        <div className="container">
            <a className="logo">
                <img src={logo} alt=""/>
            </a>

            <div className="pages">
                <a href="#useCases" className="link">{t('Use Cases')}</a>
                <a href="#pricing" className="link">{t('Pricing')}</a>
                <a href="#faq" className="link">{t('FAQ')}</a>
            </div>

            <div className="actions">
                <Dropdown customButton={<div className={'flex align-center'}>
                    <a href="#" className={'lang'}>{i18n.language}</a>
                    <ArrowBottomIcon width={15} height={15} color={'#000'}/>
                </div>} items={languageLinks}/>
                <div className="divider"/>
                <a href="https://dashboard.elokcar.com/" className={'login'}>{t('Log In')}</a>
                <div className="divider"/>
                <a href="https://elok.bike/site/register">{t('Sign Up')}</a>
            </div>

            <div className="mobile flex align-center">
                <Dropdown customButton={<div className={'flex align-center'}>
                    <a href="#" className={'lang'}>{i18n.language}</a>
                    <ArrowBottomIcon width={15} height={15} color={'#000'}/>
                </div>} items={languageLinks}/>
                <Dropdown items={links}/>
            </div>
        </div>
    </Wrapper>);
};

export default Header;
