import './App.css';
import Home from "./pages/Home";
import {ThemeProvider, createGlobalStyle} from "styled-components";
import theme from "./config/theme";

const GlobalStyle = createGlobalStyle`

  .flex {
    display: flex;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-around {
    justify-content: space-around;
  }

  .justify-center {
    justify-content: center;
  }

  .flex-column {
    flex-direction: column;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .align-center {
    align-items: center;
  }

  p {
    font-size: 1.2em;
  }
`;

function App() {
    return (<ThemeProvider theme={theme}>
        <GlobalStyle/>
        <div className="App">
            <Home/>
        </div>
    </ThemeProvider>);
}

export default App;
