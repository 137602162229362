import React, {useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Specifications = () => {
    const {t} = useTranslation();

    const [current, setCurrent] = useState('3G');

    return (
        <Wrapper>
            <div className="container">
                <div className="heading flex justify-center align-center"
                     style={{backgroundImage: `url(https://elok.bike/theme/images/pages/landing/out.png)`}}>
                    <h1>{t('Specifications')}</h1>
                </div>

                <div className="items flex justify-around">
                    <div className="first item flex flex-column">
                        <h3>&shy;</h3>
                        <p>{t('Dimensions (W*L*H)')}</p>
                        <p>{t('Energy Saving Software Driven')}</p>
                        <p>{t('GPS AND GSM geo-location')}</p>
                        <p>{t('BATTERY LIFE Avg.')}</p>
                        <p>{t('Low-battery alerts')}</p>
                    </div>
                    <div className="txt1 item flex flex-column align-center">
                        <h3>{t('3G version')}</h3>
                        <p>{t('13*90*11')}</p>
                        <p>{t('YES')}</p>
                        <p>{t('YES')}</p>
                        <p>{t('50 days in Stand-by')}</p>
                        <p>{t('YES')}</p>
                    </div>
                    <div className="txt item flex flex-column align-center">
                        <h3>{t('B-LTE version')}</h3>
                        <p>{t('B-13*90*11')}</p>
                        <p>{t('B-YES')}</p>
                        <p>{t('B-YES')}</p>
                        <p>{t('B-50 days in Stand-by')}</p>
                        <p>{t('B-YES')}</p>
                    </div>
                </div>

                <div className="mobile flex flex-column  justify-center">
                    <div className="toggle flex justify-between">
                        <button onClick={() => setCurrent('3G')} className={current === '3G' ? 'active' : ''}>
                            {t('3G version')}
                        </button>
                        <button onClick={() => setCurrent('LTE')} className={current === 'LTE' ? 'active' : ''}>
                            {t('LTE version')}
                        </button>
                    </div>

                    {current === '3G' && <div className="specs">
                        <div className="item flex justify-between align-center">
                            <h3>{t('Dimensions (W*L*H)')}</h3>
                            <p>{t('13*90*11')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('Energy Saving Software Driven')}</h3>
                            <p>{t('YES')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('GPS AND GSM geo-location')}</h3>
                            <p>{t('YES')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('BATTERY LIFE Avg.')}</h3>
                            <p>{t('50 days in Stand-by')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('Low-battery alerts')}</h3>
                            <p>{t('YES')}</p>
                        </div>
                    </div>}

                    {current === 'LTE' && <div className="specs">
                        <div className="item flex justify-between align-center">
                            <h3>{t('Dimensions (W*L*H)')}</h3>
                            <p>{t('13*90*11')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('Energy Saving Software Driven')}</h3>
                            <p>{t('YES')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('GPS AND GSM geo-location')}</h3>
                            <p>{t('YES')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('BATTERY LIFE Avg.')}</h3>
                            <p>{t('50 days in Stand-by')}</p>
                        </div>
                        <div className="item flex justify-between align-center">
                            <h3>{t('Low-battery alerts')}</h3>
                            <p>{t('YES')}</p>
                        </div>
                    </div>}
                </div>
            </div>
        </Wrapper>
    );
};


const Wrapper = styled.div`
  background-color: #7B7B7B;
  padding: 50px 0;

  .mobile {
    padding: 5px 10px;
    max-width: 500px;
    margin: 0 auto;
    display: none;
  }

  .specs {
    border-top: 2px solid #fff;
    margin-top: 1.2em;

    h3 {
      font-size: 1.1em;
    }

    p {
      font-size: 0.9em;
    }
  }

  .toggle {
    button {
      padding: 0;
      border: none;
      cursor: pointer;
      color: #fff;
      background-color: transparent;
      border-bottom: 2px solid transparent;
      font-size: 1.5em;
      outline: none;
      font-weight: bold;

      &.active {
        border-bottom: 2px solid ${props => props.theme.primary};
      }
    }
  }


  .heading {
    width: 100%;
    min-height: 15em;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h1 {
      margin-top: -0.5em;
      color: #fff;
      font-size: 3em;
    }
  }

  .items {
    width: 100%;
  }

  h3 {
    color: #fff;
    font-weight: 500;
    margin-bottom: 1em;
    font-size: 1.5em;
  }

  p {
    color: #fff;
    font-size: 1.2em;
    margin: 1em 0;
  }

  .first {
    p {
      font-weight: 500;
    }
  }

  .txt1 {
    width: 350px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  @media (max-width: 600px) {
    .mobile {
      display: block;
    }

    .items {
      display: none;
    }

    .heading {
      min-height: 7em;

      h1 {
        font-size: 1.5em;
      }
    }
  }
`;

export default Specifications;
