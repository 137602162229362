import React from 'react';

const ICLocation = () => {
    return (<svg width="84" height="100" viewBox="0 0 84 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1068_365)">
                <path
                    d="M41.9327 0C18.7629 0 0 18.6509 0 41.6823C0 72.6955 38.0125 98.28 39.5806 99.3013C40.2835 99.785 41.0946 100 41.9057 100C42.7168 100 43.5278 99.785 44.2308 99.3013C45.8529 98.2263 83.8114 72.6955 83.8114 41.6823C83.7843 18.6509 65.0484 0 41.9327 0ZM62.2097 62.537C56.6132 68.2612 50.0165 71.0293 42.149 71.0293C34.2004 71.0293 27.5766 68.2075 21.818 62.4832C16.0593 56.7589 13.2206 50.1478 13.2206 42.3273C13.2206 34.5069 16.0593 27.842 21.818 22.0102C27.5766 16.1784 34.2275 13.3029 42.0949 13.3029C49.9353 13.3029 56.5051 16.1247 62.1556 21.9027C67.8602 27.6807 70.6449 34.3994 70.6449 42.2198C70.6449 50.1478 67.8602 56.8127 62.2097 62.537Z"
                    fill="#B2CC4A"/>
            </g>
            <defs>
                <clipPath id="clip0_1068_365">
                    <rect width="83.8384" height="100" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default ICLocation;
