import React from 'react'

export default function ICPlus({color}) {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
                <path fill={color}
                      d="M2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3C2.44772 13 2 12.5523 2 12Z"
                      clipRule="evenodd" fillRule="evenodd" undefined="1"></path>
                <path fill={color}
                      d="M12 2C12.5523 2 13 2.44772 13 3V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V3C11 2.44772 11.4477 2 12 2Z"
                      clipRule="evenodd" fillRule="evenodd" undefined="1"></path>
            </svg>
        </>
    )
}
